import * as React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import Appointments from '../../components/Appointments/Appointments'
import Button from '../../components/Button/Button'
import Underline from '../../components/Underline/Underline'
import Title from '../../components/Title/Title'
import TxtImg from '../../components/TxtImg/TxtImg'
import * as CranialStyles from './cranial-osteopathy.module.css'

function Cranial({ data }) {
    return (
        <Layout>
            <Helmet>
                <title>Cranial Osteopathy | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <section className={CranialStyles.container}>
                <Title>
                    <h1>Cranial Osteopathy</h1>
                    <h2>Restore a state of balance and release by offering the body gentle and specific support where it is needed.</h2>
                    <Button external={true} to="https://eubookings.nookal.com/bookings/location/GGNJC" type="primaryOutline">Book online today</Button>
                </Title>
            </section>
            <section className="condense">
                <TxtImg split="half" imgFirst={false} imgSrc={data.cranial1} alt="Baby receiving treatment">
                    <Underline>
                        <h2>What is Cranial Osteopathy?</h2>
                    </Underline>
                    <p>Cranial osteopathy is not different to osteopathy, it is the name given to a subtle and refined approach to osteopathy that follows all the principles of osteopathy, but that includes the anatomy and physiology of the head.</p>
                    <p>The osteopath is often drawn to areas in the body that have been affected by past events, such as old accidents and injuries. The body may have learned to compensate for a traumatic event or injury and the patient may be unaware that there is anything wrong, but the effects may still be present and relevant to current symptoms.</p>
                    <p>Using this approach, the osteopath learns to listen to and be guided by the body’s inner knowledge of what is wrong, which may be different to the patient’s opinion and the osteopath’s opinion. This helps the osteopath to understand and treat the cause of the symptoms, to reduce the chance of symptoms returning in the future.</p>
                </TxtImg>
            </section>
            <section>
                <Appointments fees="cranial" />
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        cranial1: file(relativePath: {eq: "cranial.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                )
            }
        }
    }
`

export default Cranial